import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Table,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import { getStyles } from "./ViewOrder.style";
import Section from "../../Components/Section/Section";
import {
  FaAngleRight,
  FaArrowLeft,
  FaArrowRight,
  FaRegClock,
  FaSort,
} from "react-icons/fa";
import Search from "../../Components/Search/Search";
import shopImage from "../../assets/images/shop-icon.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/onion.png";
import { Link, useNavigate } from "react-router-dom";
import { fetchOrderDetailsStatus, fetchSellerCancellationReasons, getOrderDetails, Search_Store_Orders, sellerCancelLineItem } from "../../Api";
import { base_url } from "../../Api/ApiConst";
import noProduct from "../../assets/images/no-product.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface Product {
  product: {
    product_image: string;
    product_name: string;
    PRDWeight: string;
    PRDPrice: number;
    id: string; // Ensure this line is added
  };
  price: string;
  quantity: number;
  status: string;
  note:string;
}

interface CartDetails {
  street_address: string;
  state: string;
  post_code: string;
  order_note: string;
}

interface OrderDetailsResponse {
  status: boolean;
  message: string;
  order_status: string;
  product_list: Product[];
  cart_details: CartDetails;
  total: number;
}



const ViewOrder: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    "Order",
  ];
  const handleSearch = (query: string) => {
    // Implement your search logic here
    console.log("Searching for:", query);
  };
  // interface Category {
  //   id: string;
  //   quantity: string;
  //   price: string;
  //   product?:{
  //     product_image: number;
  //   };
  //   order_details:any
  // }

  interface Category {
    message: string;
    status: boolean;
    user_order_list?: {
      amount: string;
      order_details?: [];
    };
  }

  const [sortBy, setSortBy] = useState<string>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeName, setStoreName] = useState<string | null>("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [product, setProduct] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const [store, setStore] = useState<string>("");
  const [orderId, setOrderId] = useState<string | null>("");
  const navigate = useNavigate();

  const [showUpdateOrderModal, setShowUpdateOrderModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState<string | null>(null);
  const [statusOptions, setStatusOptions] = useState<string[]>([]);
  const [cancellationReason, setCancellationReason] = useState<string | null>(null);
  const [reasonOptions, setReasonOptions] = useState<string[]>([]);
  const [otherCancelReason, setOtherCancelReason] = useState<string | null>(null);
  const [orderDetails, setOrderDetails] = useState<any>(null); // Adjust type based on your response
  const [error, setError] = useState<string | null>(null);
  const [currentProductId, setCurrentProductId] = useState<string | null>(null);

  const handleSort = (criteria: string) => {
    // Implement your sorting logic here
    setSortBy(criteria);
  };

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setOrderId(localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userToken) {
      fetchOrderDetails();
    }
  }, [userToken]);

  const fetchOrderDetails = async () => {
    try {
      const payload = {
        id: storeId,
        product_name: orderId,
        store_based: true,
      };
      setLoading(true);
      const response = await Search_Store_Orders(payload, currentPage);
      if (response && response.status === 200) {
        console.log(response.data, "orderlist---------->");
        // const retrieveProductDetails=getProductDetails(response.data)
        setProduct(response.data.user_order_list[0]);
        setProducts(response.data.user_order_list[0].order_details);
        // setProduct(response.data.store_product_list)
      } else {
        console.log("Error fetching Order Details");
      }
    } catch (error) {
      console.log("Error fetching Order Details", error);
    } finally{
      setLoading(false);
    }
  };

  const fetchStoreOrderDetails = async (orderId:any) => {
    try {
      setLoading(true);
      const response = await getOrderDetails(orderId);
      
      console.log('API Response:', response); // Debugging: Check the structure of the response
      
      if (response && response.product_list && response.product_list.length > 0) {
        setOrderDetails(response);
      } 
    } catch (error) {
      console.error("Error fetching Order Details", error);
      setError('Error fetching order details.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStoreOrderDetails(orderId);
  }, [orderId]);



  const getProductDetails = (productObject: any) => {
    const getExtraProductInfo = {};
    const getRequiredFields =
      productObject?.user_order_list?.order_details.find((item: any) => {});
  };

  // const handleClick = (id: any) => {
  //   const state = {
  //     product_id: id
  //   };
  //   navigate('/view-product', { state: state });
  // }

  // console.log(userToken)

  // Open modal on button click
  const handleOpenUpdateOrderModal = (productId: string) => {
    setCurrentProductId(productId); // Store the product ID
    console.log("yur product is ",productId);
    setShowUpdateOrderModal(true);
    fetchStatusOptions();
  };

  // Close modal and reset fields
  const handleCloseUpdateOrderModal = () => {
    setShowUpdateOrderModal(false);
    resetForm();
  };

  // Fetch order status options
  const fetchStatusOptions = async () => {
    try {
      const response = await fetchOrderDetailsStatus();
      if (response.status) {
        setStatusOptions(response.status_list || []); // Assuming response contains a status_list
      } else {
        toast.error('Failed to fetch order statuses');
      }
    } catch (error) {
      toast.error('Failed to fetch order statuses');
    }
  };

  // Fetch cancellation reasons if status is "Cancelled"
  const handleStatusChange = async (selectedStatus: string) => {
    setOrderStatus(selectedStatus);
    console.log("status is", selectedStatus);
    if (selectedStatus === 'Cancelled') {
      try {
        const response = await fetchSellerCancellationReasons();
        console.log("API response:", response); // Check the structure of the response
        if (response.status) {
          setReasonOptions(response.cancel_reason_list || []); // Make sure the field name is correct
          console.log("Reason options:", reasonOptions);

        } else {
          toast.error('No cancellation reasons available');
        }
      } catch (error) {
        toast.error('Failed to fetch cancellation reasons');
      }
    } else {
      setCancellationReason(null);
      setReasonOptions([]);
    }
  };
  

  // Handle form reset
  const resetForm = () => {
    setOrderStatus(null);
    setCancellationReason(null);
    setOtherCancelReason(null);
    setReasonOptions([]);
  };

  // Handle form submission
  // const handleUpdateOrder = async () => {
  //   console.log("order id -",orderId);
  //   console.log("cancellation Reason -",cancellationReason);
  //   console.log("other Cancel Reason -",otherCancelReason);
  //   console.log("product id -",currentProductId);
  //   console.log("orderStatus id -",orderStatus);
    
  //   if (!orderStatus) {
  //     toast.error('Please select an order status');
  //     return;
  //   }

  //   if (!currentProductId) {
  //     toast.error('Product information is incomplete');
  //     return;
  //   }
  
  //   if (orderStatus === 'Cancelled') {
  //     if (!cancellationReason || (cancellationReason === 'Other Reasons' && !otherCancelReason)) {
  //       toast.error('Please provide a cancellation reason');
  //       return;
  //     }
  //     console.log("order id -",orderId);
  //     console.log("product id -",currentProductId);
  //     console.log("cancellation Reason -",cancellationReason);
  //     console.log("other Cancel Reason -",otherCancelReason);
  //     // Perform the cancellation request
  //     try {
  //       await sellerCancelLineItem(
  //         orderId!,
  //         currentProductId,
  //         orderStatus,
  //         cancellationReason,
  //         otherCancelReason
  //       );
  //       toast.success('Order cancelled successfully!');
  //       fetchOrderDetails();
  //     } catch (error) {
  //       toast.error('Failed to cancel the order');
  //     }
  //   } else {
  //     // Other status update logic if necessary
  //     toast.success('Order status updated successfully!');
  //     fetchOrderDetails();

  //   }

  //   handleCloseUpdateOrderModal();
  // };

  const handleUpdateOrder = async () => {
    console.log("order id -", orderId);
    console.log("cancellation Reason -", cancellationReason);
    console.log("other Cancel Reason -", otherCancelReason);
    console.log("product id -", currentProductId);
    console.log("orderStatus -", orderStatus);
  
    if (!orderStatus) {
      toast.error('Please select an order status');
      return;
    }
  
    if (!currentProductId) {
      toast.error('Product information is incomplete');
      return;
    }
  
    // Prepare data payload based on the selected status
    const data: any = {
      order_id: orderId!,
      product_id: currentProductId,
      order_status: orderStatus,
    };
  
    if (orderStatus === 'Cancelled') {
      if (!cancellationReason || (cancellationReason === 'Other Reason' && !otherCancelReason)) {
        toast.error('Please provide a cancellation reason');
        return;
      }
      data.cancel_reason = cancellationReason;
      if (cancellationReason === 'Other Reason') {
        data.other_reason = otherCancelReason;
      }
    }
  
    try {
      await sellerCancelLineItem(data.order_id, data.product_id, data.order_status, data.cancel_reason, data.other_reason);
      toast.success('Order status updated successfully!');
      await fetchStoreOrderDetails(data.order_id);
    } catch (error) {
      toast.error('Failed to update the order');
    }
  
    handleCloseUpdateOrderModal();
  };
  

  return (
    <>
      <Section className="viewOrder" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={11} className="px-5">
              <h2 style={styles.orderId}>
                {t("viewOrder.orderId")} : #{orderId}
              </h2>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="view-order" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          {loading && (
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <Spinner animation="grow" variant="primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          )}
          {!loading && product && (
            <Container>
              <div className="d-flex align-items-center justify-content-between">
                <div className="">
                  <Link to="/orders">
                    <FaArrowLeft className="arrow-left" />{" "}
                  </Link>
                </div>
                <div>
                  <h3 style={styles.viewOrderInfo}>
                    {t("viewOrder.orderDate")} :{" "}
                    <span style={styles.info}>
                      {String(product.order_date).split("T")[0]}
                    </span>
                  </h3>
                  <h3 style={styles.viewOrderInfo}>
                    {t("orderList.status")} :{" "}
                    <span style={styles.info}>{product.status}</span>
                  </h3>
                </div>
              </div>
              </Container>
            )}
          {!loading && orderDetails && (
            <Container>
              {/* <div className="d-flex align-items-center justify-content-between">
                <div className="">
                  <Link to="/orders">
                    <FaArrowLeft className="arrow-left" />{" "}
                  </Link>
                </div>
                <div>
                  <h3 style={styles.viewOrderInfo}>
                    {t("viewOrder.orderDate")} :{" "}
                    <span style={styles.info}>
                      {String(orderDetails.order_date).split("T")[0]}
                    </span>
                  </h3>
                  <h3 style={styles.viewOrderInfo}>
                    {t("orderList.status")} :{" "}
                    <span style={styles.info}>{orderDetails.order_status}</span>
                  </h3>
                </div>
              </div> */}
              <div style={styles.orderTableWrap}>
                <Table style={styles.orderTable} className="productTable">
                  <thead>
                    <tr>
                      <th className="hide-xs"></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th style={{ ...styles.th, textAlign: "center" }}>
                        {t("userProfile.qty")}
                      </th>
                      <th style={{ ...styles.th, textAlign: "center" }}>
                        {t("viewOrder.amount")}
                      </th>
                      
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails.product_list.map((item: Product) => (
                      <tr>
                        <td className="hide-xs">
                          <div style={styles.dot}></div>
                        </td>
                        <td>
                          <div style={styles.orderImageWrap}>
                            <Image
                              src={base_url + item.product.product_image}
                              style={styles.orderImage}
                            />
                          </div>
                        </td>
                        <td style={styles.orderInfo}>
                          <p
                            className="mb-0"
                            style={{ fontFamily: "var(--primary-font-medium)" }}
                          >
                            {item.product.product_name}
                          </p>
                          <p
                            className="mb-0"
                            style={{
                              fontFamily: "var(--primary-font-regular)",
                              fontSize: 16,
                            }}
                          >
                            {item.product.PRDWeight}
                          </p>
                        </td>
                        <td>
                                    <div className="lineItem">
                                      <div><span className="key">{t("cancelItem.status")}: </span><span className="value">{item.status}</span></div>
                                      {item.status === "Cancelled" ? (
  <div><span className="key">{t("cancelItem.cancellationReason")}: </span><span className="value">{item.note}</span></div> ) : (
    <></>
  )}
                                    </div>
                                 
                        </td>
                        <td style={{ ...styles.orderInfo, textAlign: "center" }}>
                          {item.quantity}
                        </td>
                        <td
                          style={{
                            ...styles.orderInfo,
                            textAlign: "center",
                            fontFamily: "var(--primary-font-semibold)",
                          }}
                        >
                          {item.price}
                        </td>
                       
                        <td style={{textAlign:"right"}}>
                        {/* {item.status === "New" ? ( */}
                          <Button className="btnBlue" onClick={() => handleOpenUpdateOrderModal(item.product.id)}>
                            Update Order
                          </Button>
                          {/* ) : (<></>)} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <hr style={{ borderTopColor: "#000" }} />
              <div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span style={styles.value500}>{t("viewOrder.mrpTotal")}</span>
                  <span style={styles.value600}>₹ {orderDetails.total.toFixed(2)}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span style={styles.value500}>{t("viewOrder.tax")}</span>
                  <span style={styles.value400}>0</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span style={styles.value500}>{t("viewOrder.shipping")}</span>
                  <span style={styles.value400}>0</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span style={styles.value500}>
                    Discount 
                    {/* ({orderDetails.cart_details.order_note}) */}
                  </span>
                  <span style={styles.value400}>₹ 0</span>
                </div>
              </div>
              <hr style={{ borderTopColor: "#000" }} />
              <div className="d-flex align-items-center justify-content-between mb-2">
                <span style={styles.value600}>{t("viewOrder.amount")}</span>
                <span style={styles.value600}>₹ {orderDetails.total.toFixed(2)}</span>
              </div>
              <Row className="mt-5">
                <Col>
                  <h2 style={styles.label}>Delivery Address</h2>
                  <div style={{ ...styles.value, textAlign: "left" }}>
                    <span>{orderDetails.cart_details.street_address}</span>, <span>{orderDetails.cart_details.state}</span>, <span>{orderDetails.cart_details.post_code}</span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h2 style={styles.label}>{t("viewOrder.deliveryZipCode")}</h2>
                  <div style={{ ...styles.value, textAlign: "left" }}>
                    {orderDetails.cart_details.post_code || "Delivery pincode"}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h2 style={styles.label}>{t("viewOrder.productDeliveredNote")}</h2>
                  <div style={{ ...styles.value, textAlign: "left" }}>
                    {orderDetails.cart_details.order_note || t("viewOrder.deliveryNote")}
                  </div>
                </Col>
              </Row>
            </Container>
          )}
          
        </div>
      </Section>

      {/* Update Order Modal */}
      <Modal show={showUpdateOrderModal} onHide={handleCloseUpdateOrderModal} className="cancelOrderModal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Order Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="orderStatus">
              <Form.Label>Select Order Status:</Form.Label>
              <Form.Control
                as="select"
                value={orderStatus || ""}
                onChange={(e) => handleStatusChange(e.target.value)}
              >
                <option value="">Select a status</option>
                {statusOptions.map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {orderStatus === 'Cancelled' && (
  <Form.Group controlId="cancellationReason" className="mt-2">
    <Form.Label>Reason for Cancellation:</Form.Label>
    <Form.Control
      as="select"
      value={cancellationReason || ""}
      onChange={(e) => setCancellationReason(e.target.value)}
    >
      <option value="">Select a reason</option>
      {reasonOptions.length > 0 ? (
        reasonOptions.map((reason, index) => (
          <option key={index} value={reason}>
            {reason}
          </option>
        ))
      ) : (
        <option value="">No reasons available</option>
      )}
    </Form.Control>
  </Form.Group>
)}

            {cancellationReason === 'Other Reason' && (
              <Form.Group controlId="otherCancelReason" className="mt-2">
                <Form.Label>Please specify the reason:</Form.Label>
                <Form.Control
                  type="text"
                  value={otherCancelReason || ""}
                  onChange={(e) => setOtherCancelReason(e.target.value)}
                />
              </Form.Group>
            )}

            {error && <p className="text-danger">{error}</p>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btnCancel" variant="secondary" onClick={handleCloseUpdateOrderModal}>
            Close
          </Button>
          <Button className="btnBlue" variant="primary" onClick={handleUpdateOrder}>
            Update Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewOrder;
